import { useEffect, useState, useRef } from 'react';

import { If, toClassName, createComponent, IntrinsicProps } from '@common/util/templateHelpers';
import Flex from '@common/components/Flex';
import Spacer, { SpacerImage } from '@common/components/Spacer';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { CSSTransition } from 'react-transition-group';
import { useSupportsTouch } from '@common/hooks/useSupportsTouch';
import { EventMap, EventListener } from '@common/util/eventListener';

enum GamePreviewState {
  WAITING = 'waiting',
  INTERSTITIAL = 'interstitial',
  LOADING = 'loading',
  HIDDEN = 'hidden',
  ERROR = 'error'
}

type ClickPlayHandler = (loadGame: () => void) => void

export interface GamePlayerProps extends IntrinsicProps {
  previewImage: string
  gameSrc: string
  autoPlay?: boolean | 'afterInterstitial'
  instantPlayQs?: string
  boundingBox?: DOMRect
  fullScreen?: boolean
  width?: number
  height?: number
  isFlash?: boolean
  onClickPlay: ClickPlayHandler
  onClickExitFullScreen?: () => void
  onClickEnterFullScreen?: () => void
  supportsMobile?: {
    portrait?: boolean
    landscape?: boolean
  }
  spacer?: SpacerImage
  fluid?: boolean
  gameOverlayTransition?: string
  gameOverlayTransitionTimeout?: number
  bottomPromoTransition?: string
  bottomPromoTransitionTimeout?: number
  hideFullScreenCloseButton?: boolean
  resetOnExitFullScreen?: boolean | 'mobile'
  embedTag?: 'object' | 'iframe' | 'html'
}

const gamePlayerStates = [
  'fluid'
];

export type GamePlayerEvents = EventMap & {
  'play': (event: {}) => void
}

class GamePlayerEventListener extends EventListener<GamePlayerEvents> {}
export const gamePlayerEventListener = new GamePlayerEventListener();

export default createComponent<GamePlayerProps>('GamePlayer', { classStates: gamePlayerStates }, function GamePlayer ({ className, mergeClassNames, style, slots }, props) {
  // Component state
  const [ showGame, setShowGame ] = useState(false);
  const [ previewState, setPreviewState ] = useState(GamePreviewState.WAITING);
  const el = useRef<HTMLDivElement>(null);
  const embedEl = useRef<any>(null);
  const [ fullScreen, setFullScreen ] = useState(props.fullScreen);
  const router = useRouter();
  const supportsTouch = useSupportsTouch();
  const supportsTouchRef = useRef(supportsTouch);

  // If navigation occurs make sure we're no longer in fullscreen mode
  useEffect(() => {
    if (!props.instantPlayQs) return;
    if (router.asPath.includes(props.instantPlayQs)) return;

    const onRouteChange = () => setFullScreen(false);
    router.events.on('routeChangeStart', onRouteChange);

    return () => {
      router.events.off('routeChangeStart', onRouteChange);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ router.events ]);

  // Set the touch support value once to avoid re-renders and full-screen bugs on mobile
  useEffect(() => {
    supportsTouchRef.current = supportsTouch;
  }, [ supportsTouch ]);

  // If navigation occurs make sure we're no longer in fullscreen mode
  useEffect(() => {
    const onRouteChange = () => setFullScreen(false);
    router.events.on('routeChangeStart', onRouteChange);
    return () => router.events.off('routeChangeStart', onRouteChange);
  }, [ router.events ]);

  // Preview/interstial logic
  const previewStyles = {
    backgroundImage: `url(${props.previewImage})`
  };

  // Watch preview state
  const initialRender = useRef(true);
  const onClickPlay = props.onClickPlay;
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (previewState === GamePreviewState.INTERSTITIAL) {
      if (onClickPlay) {
        onClickPlay(() => {
          loadGame();
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ previewState ]);

  // Load game
  const loadGame = () => {
    if (previewState === GamePreviewState.LOADING) return;

    setPreviewState(GamePreviewState.LOADING);
    setShowGame(true);
  };

  {
    const { isFlash, gameSrc, embedTag } = props;
    useEffect(() => {
      if (!embedEl.current) return;
      
      (async () => {
        const el = embedEl.current;
        if (!isFlash) {
          if (embedTag === 'html') {
            el.innerHTML = gameSrc;
            gameLoaded();
          } 
          else if (embedTag === 'iframe') {
            el.setAttribute('src', gameSrc);
          } 
          else {
            el.setAttribute('data', gameSrc);
          }
        }
      })();
    }, [ showGame, isFlash, gameSrc, embedTag ]);
  }

  // If unmounting component, make sure we remove ruffle
  // next/script doesn't unmount, we have to do it
  useEffect(() => {
    return () => {
      document.querySelector('#ruffle-config')?.remove();
      document.querySelector('#ruffle-load')?.remove();
    };
  }, []);

  // If the game changes, make sure we reset the player state
  useEffect(() => {
    setShowGame(false);
    setPreviewState(GamePreviewState.WAITING);
  }, [ props.gameSrc ]);

  // Handlers & methods
  const clickPlay = () => {
    // If the user is on mobile or a small screen then try to fullScreen the
    // game. Need to check both since some large phones in landscape mode 
    // exceed the mobile breakpoint width.
    if (supportsTouchRef.current) {
      setFullScreen(true);
    }

    if (props.onClickPlay) {
      setPreviewState(GamePreviewState.INTERSTITIAL);
    } else {
      setShowGame(true);
    }
  };

  const gameLoaded = () => {
    setPreviewState(GamePreviewState.HIDDEN);
  };

  {
    const { autoPlay } = props;
    useEffect(() => {
      if (autoPlay === 'afterInterstitial') {
        setPreviewState(GamePreviewState.INTERSTITIAL);
      } else if (autoPlay) {
        loadGame();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ autoPlay ]);

    useEffect(() => {
      const playListener = gamePlayerEventListener.on('play', () => {
        loadGame();
      });
      
      return () => {
        gamePlayerEventListener.off('play', playListener);
      };
    });
  }

  // Fullscreen logic
  const boundingBox = {
    top: props.boundingBox?.top || 0,
    left: props.boundingBox?.left || 0,
    right: props.boundingBox?.right || 0,
    bottom: props.boundingBox?.bottom || 0
  };

  // Handler for the parent to request a change to the fullScreen state
  useEffect(() => {
    setFullScreen(props.fullScreen);
  }, [ props.fullScreen ]);

  // Handler for built-in browser fullScreen API events
  useEffect(() => {
    const handler = () => {
      const doc = document as any;

      const fullScreenElement = 
        doc.fullscreenElement ||
        doc.mozFullScreenElement ||
        doc.webkitFullscreenElement ||
        doc.webkitCurrentFullScreenElement ||
        doc.msFullscreenElement;

      setFullScreen(!!fullScreenElement);
    };

    document.documentElement.addEventListener('fullscreenchange', handler);
    document.documentElement.addEventListener('webkitfullscreenchange', handler);

    return () => {
      document.documentElement.removeEventListener('fullscreenchange', handler);
      document.documentElement.removeEventListener('webkitfullscreenchange', handler);
    };
  }, []);

  // Handler for fullScreen state
  {
    // Assign these to refs so we can avoid a feed-back loop on mobile full-screen changes
    const onClickEnterFullScreenRef = useRef(props.onClickEnterFullScreen);
    const onClickExitFullScreenRef = useRef(props.onClickExitFullScreen);

    useEffect(() => {
      if (fullScreen) {
        if (onClickEnterFullScreenRef.current) onClickEnterFullScreenRef.current();
        document.body.classList.add('--fullScreen');
      } else {
        if (onClickExitFullScreenRef.current) onClickExitFullScreenRef.current();
        document.body.classList.remove('--fullScreen');

        if (
          (props.resetOnExitFullScreen === 'mobile' && supportsTouch) || 
          (props.resetOnExitFullScreen === true)
        ) {
          setShowGame(false);
          setPreviewState(GamePreviewState.WAITING);
        } 
      }

      if (	
        (props.resetOnExitFullScreen === 'mobile' && supportsTouchRef.current) || 	
        (props.resetOnExitFullScreen === true)	
      ) {	
        setShowGame(false);	
        setPreviewState(GamePreviewState.WAITING);	
      }
  
      // Bail out if either:
      // 1. This is a mobile device since many don't support the fullScreen API
      // 2. The game object element doesn't exist yet since it needs to be ready
      //    in order to use the fullScreen API
      if (supportsTouchRef.current || !el.current) return;
  
      // Otherwise, invoke the browser's fullScreen API
      const doc = document as any;
  
      const fullScreenElement = 
        doc.fullscreenElement ||
        doc.mozFullScreenElement ||
        doc.webkitFullscreenElement ||
        doc.webkitCurrentFullScreenElement ||
        doc.msFullscreenElement;
  
      if (fullScreen && !fullScreenElement) {
        const el = document.documentElement as any;
  
        const requestFullscreen = 
          el.requestFullscreen || 
          el.webkitRequestFullScreen ||
          el.webkitRequestFullscreen ||
          el.mozRequestFullScreen || 
          el.msRequestFullscreen;
  
        requestFullscreen.call(el, { navigationUI: 'hide' });
      } 
      
      if (!fullScreen && fullScreenElement) {
        const exitFullscreen = 
          doc.exitFullscreen || 
          doc.mozCancelFullScreen || 
          doc.webkitExitFullscreen || 
          doc.msExitFullscreen;
        
        exitFullscreen.call(doc);
      }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ fullScreen ]);
  }

  // Game object
  const gameStyles = {
    width: props.width,
    height: props.height
  };

  // Styles
  style = {
    ...style,
    ...(fullScreen ? boundingBox : gameStyles)
  };

  // Control fullScreen class internally
  className = mergeClassNames(toClassName('GamePlayer', { fullScreen }), className);

  return (
    <div className={className} style={style} ref={el}>
      {
        If((showGame && props.isFlash), () => (
          <>
            <Script 
              id='ruffle-load' 
              src='/scripts/ruffle.js' 
              strategy='lazyOnload'
              onLoad={gameLoaded}
            />
            <Script
              id='ruffle-config' 
              dangerouslySetInnerHTML={{
                __html: `
                  window.RufflePlayer = window.RufflePlayer || {};
                  window.RufflePlayer.config = {
                    // Options affecting the whole page
                    'publicPath': undefined,
                    'polyfills': true,
            
                    // Options affecting files only
                    'autoplay': 'auto',
                    'unmuteOverlay': 'visible',
                    'backgroundColor': null,
                    'letterbox': 'on',
                    'warnOnUnsupportedContent': true,
                    'contextMenu': true,
                    'upgradeToHttps': true,
                    'maxExecutionDuration': {'secs': 15, 'nanos': 0},
                    'logLevel': 'none',
                  };
                `
              }}
              strategy='afterInteractive'
            />
          </>
        )).EndIf()
      }
      
      <CSSTransition
        in={previewState !== GamePreviewState.HIDDEN}
        timeout={props.gameOverlayTransitionTimeout || 400}
        classNames={`${props.gameOverlayTransition || 'TxFade400'}`}
        unmountOnExit
      >
        <div className='GamePlayer__Overlay'>
          <div className='GamePlayer__PreviewOverlay' style={previewStyles} />
          {
            If(previewState === GamePreviewState.WAITING, () => (
              <Flex alignCenter justifyCenter className='GamePlayer__Overlay'>
                <div onClick={clickPlay}>
                  {slots.playButton}
                </div>
              </Flex>
            ))
              .ElseIf(previewState === GamePreviewState.INTERSTITIAL, () => (
                <Flex alignCenter justifyCenter className='GamePlayer__Overlay'>
                  {slots.interstitial}
                </Flex>
              ))
              .ElseIf(previewState === GamePreviewState.LOADING, () => (
                <Flex alignCenter justifyCenter className='GamePlayer__Overlay'>
                  <div className='GamePlayer__Loader' />
                </Flex>
              ))
              .ElseIf(previewState === GamePreviewState.ERROR, () => (
                <Flex alignCenter justifyCenter className='GamePlayer__Incompatible'>
                  {slots.error ? slots.error : 'An error occurred while loading this game. Please try again later.'}
                </Flex>
              ))
              .EndIf()
          }
          <CSSTransition 
            in={previewState !== GamePreviewState.HIDDEN && previewState !== GamePreviewState.ERROR} 
            timeout={props.gameOverlayTransitionTimeout || 400}
            classNames={`${props.bottomPromoTransition || 'TxFade400'}`}
            unmountOnExit
          >
            <div className={toClassName('GamePlayer__Anchor', '&--bottom')} style={{ width: '100%' }}>
              <Flex justifyCenter wide>
                <div>
                  {slots.bottomPromo}
                </div>
              </Flex>
            </div>
          </CSSTransition>
        </div>
      </CSSTransition>
      <div className='GamePlayer__Game' style={gameStyles}>
        {
          If(showGame, () => (
            <>
              {
                If(props.isFlash, () => (
                  <object
                    ref={embedEl} 
                    width={props.width ? props.width : '100%'} 
                    height={props.height ? props.height : '100%'}
                  >
                    <param value={props.gameSrc} />
                    <embed src={props.gameSrc} />
                  </object>
                )).Else(() => (
                  <>
                    {
                      If(props.embedTag === 'iframe', () => (
                        <iframe
                          ref={embedEl} 
                          src={props.gameSrc}
                          width={props.width ? props.width : '100%'} 
                          height={props.height ? props.height : '100%'}
                          allowFullScreen
                          scrolling='no'
                          onLoad={gameLoaded}
                          id='gameIframe'
                          allow='clipboard-write'
                        />
                      ))
                        .ElseIf(props.embedTag === 'html', () => (
                          <div
                            ref={embedEl} 
                            style={{
                              width: props.width ? props.width : '100%',
                              height: props.height ? props.height : '100%'
                            }} 
                          />
                        ))
                        .Else(() => (
                          <object 
                            ref={embedEl} 
                            width={props.width ? props.width : '100%'} 
                            height={props.height ? props.height : '100%'}
                            onLoad={gameLoaded}
                          />
                        ))
                        .EndIf()
                    }
                  </>
                )).EndIf()
              }
            </>
          ))
            .EndIf()
        }
        {
          If(props.spacer, () => (
            <Spacer wide spacer={props.spacer} />
          ))
            .EndIf()
        }
      </div>
      {
        If(!props.supportsMobile, () => (
          <Flex alignCenter justifyCenter className={toClassName('GamePlayer__Incompatible', '&--mobile')}>
            {slots.mobileUnsupported ? slots.mobileUnsupported : 'This game does not support mobile devices.'}
          </Flex>
        ))
          .Else(() => (
            <>
              {
                If(!props.supportsMobile.landscape, () => (
                  <Flex alignCenter justifyCenter className={toClassName('GamePlayer__Incompatible', '&--landscape')}>
                    {slots.landscapeUnsupported ? slots.landscapeUnsupported : 'Rotate the device into portrait mode to play this game.'}
                  </Flex>
                ))
                  .EndIf()
              }
              {
                If(!props.supportsMobile.portrait, () => (
                  <Flex alignCenter justifyCenter className={toClassName('GamePlayer__Incompatible', '&--portrait')}>
                    {slots.portraitUnsupported ? slots.portraitUnsupported : 'Rotate the device into landscape mode to play this game.'}
                  </Flex>
                ))
                  .EndIf()
              }
            </>
          ))
          .EndIf()
      }
      {
        If(fullScreen && (previewState === GamePreviewState.WAITING || showGame) && !props.hideFullScreenCloseButton, () => (
          <div className={toClassName('GamePlayer__Anchor', '&--rightSm &--topSm')} style={{ zIndex: 4 }}>
            <Flex 
              alignCenter 
              justifyCenter
            >
              <span className='GamePlayer__CloseButton' onClick={() => setFullScreen(false)}>&times;</span>
            </Flex>
          </div>
        ))
          .EndIf()
      }
    </div>
  );
});
